import Cookies from 'js-cookie'
const { set } = Cookies

export default class LibPrivacyPolicyConsent {
  constructor(container) {
    this.container = container
    this.checkbox = document.querySelector('[data-ref="privacy_checkbox_consent"]')
    this.errorMessage = document.querySelector('[data-ref="privacy_checkbox_error_block"]')

    if (this.checkbox) {
      this.checkPrivacyCheckbox()
      this.checkbox.addEventListener('change', () => this.checkPrivacyCheckbox())
    }

    this.cookieEngine()
  }

  checkPrivacyCheckbox() {
    if (this.checkbox.checked)
      this.errorMessage.dataset.hidden = 'true'
    else
      delete this.errorMessage.dataset.hidden
  }

  cookieEngine() {
    [document.getElementById('privacy-policy'), document.getElementById('terms-and-conditions')].forEach(elem => {
      elem?.addEventListener('click', e => {
        const id = e.currentTarget.id
        const currentTime = new Date().getTime()
        set(`${id}-read`, currentTime)
      })
    })
  }
}
